import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { requestAppPost } from "@/db/dbManag";

export const alertSuccess = (message) => {
  // EVENTIALMENTE AGGIUNGERE PARAMETRO IN INGLESSO idModalToClose
  // const confirmButtonText = `
  //             <span
  //               type="button"
  //               class="me-3 badge bg-light-danger rounded text-danger fs-5"
  //               data-bs-dismiss="modal"
  //               data-bs-target="#${idModalToClose}"
  //             >
  //               Chiudi
  //             </span>`;
  return Swal.fire({
    html: message,
    icon: "success",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn btn-danger border rounded",
    },
    confirmButtonText: "Chiudi",
    allowOutsideClick: false,
  }).then(() => {
    return true;
  });
};

export const alertFailed = (message) => {
  return Swal.fire({
    html: message,
    icon: "error",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn btn-danger border rounded",
    },
    confirmButtonText: "Chiudi",
    allowOutsideClick: false,
  }).then(() => {
    return true;
  });
};
export const alertFailedNoClose = (message) => {
  return Swal.fire({
    html: message,
    icon: "error",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn btn-danger border rounded",
    },
    allowOutsideClick: false,
  }).then(() => {
    return true;
  });
};

export const callFunctionAfterAlert = async (
  endpoint,
  payload,
  question,
  textAlertSuccess,
  textAlertFailed,
  nonMostrarePopup
) => {
  return Swal.fire({
    html: question ? question : "Sei sicuro?",
    confirmButtonText: "Conferma",
    showCancelButton: true,
    icon: "warning",
    cancelButtonText: "Annulla",
    allowOutsideClick: false,
    showLoaderOnConfirm: true,
    customClass: {
      confirmButton: "btn fw-bold btn-light-primary",
      cancelButton: "btn fw-bold btn-light-danger",
    },
    preConfirm: () => {
      return requestAppPost(endpoint, payload).then(
        ({ data, status: reqStatus, statusText }) => {
          return { data, status: reqStatus, statusText };
        }
      );
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      const { data, status: reqStatus } = result.value;
      if (
        (reqStatus != 200 && reqStatus != 204) ||
        (reqStatus == 200 &&
          ((data.statusCode && data.statusCode != 200) ||
            (data.StatusCode && data.StatusCode != 200)))
      ) {
        nonMostrarePopup
          ? ""
          : alertFailed(data.message ? data.message : textAlertFailed);
        return false;
      } else {
        nonMostrarePopup ? "" : alertSuccess(textAlertSuccess);
        return data;
      }
    } else {
      return false;
    }
  });
};

export const alertChoiceNoFunction = async (question) => {
  return Swal.fire({
    html: question ? question : "Sei sicuro?",
    confirmButtonText: "Conferma",
    showCancelButton: true,
    icon: "warning",
    cancelButtonText: "Annulla",
    allowOutsideClick: false,
    showLoaderOnConfirm: true,
    customClass: {
      confirmButton: "btn fw-bold btn-light-primary",
      cancelButton: "btn fw-bold btn-light-danger",
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      return true;
    } else {
      return false;
    }
  });
};
