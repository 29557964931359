import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule.js";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

import UserCreate from "@/store/modules/UserCreate";
import OldLogin from "@/store/modules/OldLogin";
import MenuStore from "@/store/modules/MenuStore";
import StoreDinamico from "@/store/modules/StoreDinamico";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    UserCreate,
    OldLogin,
    MenuStore,
    StoreDinamico,
  },
});

export default store;
