import { requests } from "@/db/dbManag";
import { API, STATI_TORNEO } from "@/enums/costanti";

export const getDataFromApi = (keys, apiLink) => {
  return requests.dbManag
    .post(apiLink, keys)
    .then((res) => {
      return res.data.result ? res.data.result : res.data;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getDataFromListApi = (keys, apiLink) => {
  return requests.dbManag
    .post(apiLink, keys)
    .then((res) => {
      const status = res.status;
      const list = Object.values(res.data)[0];
      const record = res.data.record;
      // const { results, record, campi_Locked, comandi_Locked } = Object.values(res.data)[0];
      return { list, status, record };
    })
    .catch((e) => {
      return {
        results: [],
        record: 0,
        campi_Locked: [],
        comandi_Locked: [],
        status: e.request.status,
      };
    });
};

export const getDataFromListApiGet = (keys, apiLink) => {
  return requests.dbManag
    .get(apiLink, { params: { ...keys } })
    .then((res) => {
      const status = res.status;
      const list = Object.values(res.data)[0];
      const record = res.data.record;
      // const { results, record, campi_Locked, comandi_Locked } = Object.values(res.data)[0];
      return { list, status, record };
    })
    .catch((e) => {
      return {
        results: [],
        record: 0,
        campi_Locked: [],
        comandi_Locked: [],
        status: e.request.status,
      };
    });
};

export const getlookupFromApi = (keys) => {
  return requests.dbManag

    .post(API.LOOKUP_GET, keys)
    .then((res) => {
      return res.data.result ? res.data.result : res.data;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupRegioniProvincie = (id_regione) => {
  return requests.dbManag

    .post(API.LOOKUP_REGIONI_PROVINCIE, { id_regione })
    .then((res) => {
      if (id_regione) {
        return res.data.istat_regioni_province[0].Province;
      }
      return res.data.istat_regioni_province;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupRegioniProvincieFe = (id_regione) => {
  return requests.dbManag

    .post(API.LOOKUP_REGIONI_PROVINCIE_FE, { id_regione })
    .then((res) => {
      if (id_regione) {
        return res.data.istat_regioni_province[0].Province;
      }
      return res.data.istat_regioni_province;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupTorneiEventiCalendario = (id_settore) => {
  return requests.dbManag

    .post(API.LOOKUP_TORNEI_EVENTI_CALENDARIO, {
      id_settore,
      nuovi_eventi: true,
    })
    .then((res) => {
      return res.data.tornei_eventi_calendario;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};

export const getLookupCircuiti = (id_settore, isPrimario, data_riferimento) => {
  return requests.dbManag

    .post(API.LOOKUP_CIRCUITI, {
      id_settore,
      primario: isPrimario,
      data_riferimento,
    })
    .then((res) => {
      return res.data.circuiti;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupCircuitiPrimari = (
  id_settore,
  isPrimario,
  data_riferimento
) => {
  return requests.dbManag

    .post(API.LOOKUP_CIRCUITI_PRIMARI, {
      id_settore,
      primario: isPrimario,
      data_riferimento,
    })
    .then((res) => {
      return res.data.circuiti;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupImpianti = (id_societa, anno) => {
  return requests.dbManag

    .post(API.LOOKUP_IMPIANTI, { id_societa, anno })
    .then((res) => {
      return res.data.results;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupTorneiTipologie = (id_settore) => {
  return requests.dbManag

    .post(API.LOOKUP_TORNEI_TIPOLOGIE, { id_settore })
    .then((res) => {
      return res.data.tornei_tipologie;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupTorneiCategorieEta = (id_settore) => {
  return requests.dbManag
    .post(API.LOOKUP_SETTORI_TESSERAMENTO_CATEGORIE_ETA_GET, { id_settore })
    .then((res) => {
      return res.data.settori_categorie_eta;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};

export const getLookupGruppoSettore = (id_settore, id_tipologia) => {
  return requests.dbManag

    .post(API.LOOKUP_TORNEI_SETTORI_GRUPPI, { id_settore, id_tipologia })
    .then((res) => {
      return res.data.tornei_settori_gruppi;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};

export const getLookupLimitePower = (id_settore_gruppo, id_tipologia) => {
  return requests.dbManag

    .post(API.LOOKUP_TORNEI_SETTORI_GRUPPI_P_RANGE, {
      id_settore_gruppo,
      id_tipologia,
    })
    .then((res) => {
      return res.data.settori_gruppi_tipologie_power_range;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};

export const getLookupTipologieTabelloni = (
  id_formula_svolgimento,
  id_stato,
  nr_iscrizioni
) => {
  return requests.dbManag

    .post(API.LOOKUP_TORNEI_TABELLONI_DIMENSIONI, {
      id_formula_svolgimento,
    })
    .then((res) => {
      const result = [];
      // nr_iscrizioni = 15;
      // console.log(
      //   "nr_iscrizioni",
      //   nr_iscrizioni,
      //   res.data.tornei_tabelloni_dimensioni
      // );
      res.data.tornei_tabelloni_dimensioni.forEach((dimensione_tab) => {
        if (
          nr_iscrizioni <= dimensione_tab.iscritti_min ||
          nr_iscrizioni <= dimensione_tab.iscritti_max ||
          (nr_iscrizioni >= dimensione_tab.iscritti_min &&
            nr_iscrizioni < dimensione_tab.iscritti_max)
        ) {
          result.push(dimensione_tab);
        }
      });
      return id_stato == STATI_TORNEO.APPROVATO
        ? result
        : res.data.tornei_tabelloni_dimensioni;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};

export const getLookupTorneiPremi = (isDoppio) => {
  return requests.dbManag

    .post(
      API.LOOKUP_TORNEI_PREMI,
      isDoppio
        ? {
            singolo: false,
            doppio: true,
          }
        : {
            singolo: true,
            doppio: false,
          }
    )
    .then((res) => {
      return res.data.tornei_premi;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};

export const getLookupGironiCombinazioni = (id_tabellone) => {
  return requests.dbManag

    .post(API.LOOKUP_TORNEI_GIRONI_COMBINAZIONI, {
      id_tabellone,
    })
    .then((res) => {
      return res.data.gironi_combinazioni;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupGironiCombinazioniCampionato = (id_tabellone) => {
  return requests.dbManag

    .post(API.LOOKUP_SQUADRE_GIRONI_COMBINAZIONI, {
      id_tabellone,
    })
    .then((res) => {
      return res.data.gironi_combinazioni;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupCampionatoSquadre = (id_settore) => {
  return requests.dbManag

    .post(API.LOOKUP_SQUADRE_CAMPIONATI, {
      id_settore,
      iscrizioni_attive: null,
      archiviato: false,
    })
    .then((res) => {
      return res.data.squadre_campionati;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupZonaSquadre = (id_campionato_squadre) => {
  return requests.dbManag

    .post(API.LOOKUP_SQUADRE_ZONA, {
      id_campionato_squadre,
    })
    .then((res) => {
      return res.data.squadre_zona;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupZonaCampionatiRegione = (id_campionato_squadre) => {
  return requests.dbManag

    .post(API.LOOKUP_SQUADRE_CAMPIONATI_REGIONI_ZONE_GET, {
      id_campionato_squadre,
    })
    .then((res) => {
      return res.data.squadre_regioni_zone;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupGiornoDisponibili = () => {
  return requests.dbManag

    .post(API.LOOKUP_SQUADRA_GIORNI_DISPONIBILI, {})
    .then((res) => {
      return res.data.squadre_giorni_disponibili_in_casa;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupFasi = () => {
  return requests.dbManag

    .post(API.LOOKUP_SQUADRE_FASI, {})
    .then((res) => {
      return res.data.squadre_fasi;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupRuoli = () => {
  return requests.dbManag

    .post(API.LOOKUP_SISTEMA_RUOLI_GET, { tipo: 1 })
    .then((res) => {
      return res.data.ruoli;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
export const getLookupOrariDisponibili = (id_giorno) => {
  return requests.dbManag

    .post(API.LOOKUP_SQUADRA_ORARI, { id_giorno })
    .then((res) => {
      return res.data.squadre_orari;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};

export const getListaSocietaCoordinatori = (id_persona) => {
  return requests.dbManag

    .post(API.ANAGRAFICA_SOCIETA_COORDINATORI_LIST, { id_persona })
    .then((res) => {
      return res.data.results[0].societa;
    })
    .catch((e) => {
      console.log("Errore! " + e);
    });
};
