import { RouteLocationNormalized, Router } from "vue-router";
import { msalInstance, loginRequest } from "../authConfig";
import {
  InteractionType,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import store from "@/store";

// document.cookie = `changed_credentials=true; expires=${expires}; Path=/`;

export function registerGuard(router: Router) {
  const cookie: any = document.cookie;
  const hasChangedCredential = cookie.includes("changed_credentials=true");
  const account = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();
  const isManutenzione = store.getters.isManutenzione;
  const redirect_comunicazioni = store.getters.redirect_comunicazioni;

  router.beforeEach(async (to: RouteLocationNormalized) => {
    localStorage.setItem("lastLocation", to.fullPath);
    const pagine_abilitate = store.getters.pagine_abilitate;
    const nomePaginaSenzaParametri =
      to.params.id || to.params.guid
        ? to.params.id
          ? to.fullPath.substring(
              0,
              to.fullPath.indexOf(to.params.id.toString())
            )
          : to.fullPath.substring(
              0,
              to.fullPath.indexOf(to.params.guid.toString())
            )
        : to.fullPath;
    // if (isManutenzione) return "/manutenzione";
    if (
      pagine_abilitate &&
      pagine_abilitate.length &&
      !nomePaginaSenzaParametri
    ) {
      return false;
    }
    if (!accounts.length && to.meta.noAuth === true) {
      return true;
    }
    if (!hasChangedCredential && !to.query.FCHT) {
      const request = {
        ...loginRequest,
        redirectStartPage: to.fullPath,
      };
      const shouldProceed = await isAuthenticated(
        msalInstance,
        InteractionType.Redirect,
        request
      );
      return shouldProceed;
      // if (to.meta.noAuth) {
      //   return true;
      // }
      // else {
      //   return "/creazione-credenziali";
      // }
    } else if (!hasChangedCredential && to.query.FCHT) {
      const request = {
        ...loginRequest,
        redirectStartPage: to.fullPath,
      };
      const shouldProceed = await isAuthenticated(
        msalInstance,
        InteractionType.Redirect,
        request
      );
      return shouldProceed;
    } else {
      // if (!hasChangedCredential) {
      //   return "/creazione-credenziali";
      // }
      if (account) {
        if (to.meta.noAuth) {
          return "/gestione-tornei/gestione-manifestazioni";
        } else {
          return true;
        }
      } else {
        const request = {
          ...loginRequest,
          redirectStartPage: to.fullPath,
        };
        const shouldProceed = await isAuthenticated(
          msalInstance,
          InteractionType.Redirect,
          request
        );
        return shouldProceed;
      }
    }
  });
}

export async function isAuthenticated(
  instance: PublicClientApplication,
  interactionType: InteractionType,
  loginRequest: PopupRequest | RedirectRequest
): Promise<boolean> {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not

  return instance
    .handleRedirectPromise()
    .then(() => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        return true;
      }

      // User is not signed in and attempting to access protected route. Sign them in.
      if (interactionType === InteractionType.Popup) {
        return instance
          .loginPopup(loginRequest)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      } else if (interactionType === InteractionType.Redirect) {
        return instance
          .loginRedirect(loginRequest)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      }

      return false;
    })
    .catch(() => {
      instance.logoutRedirect();
      return false;
    });
}
