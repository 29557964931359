<template>
  <div class="mx-auto my-auto text-center py-5 my-5">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
