import { msalInstance } from "@/authConfig";

const getDefaultState = () => {
  return {
    tokenADB2C: "",
  };
};

const state = getDefaultState();

const getters = {
  tokenADB2C: (state) => state.tokenADB2C,
};
const actions = {
  Logout: async () => {
    msalInstance.logoutRedirect();
  },
  Login: async () => {
    const expires = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );
    document.cookie = `changed_credentials=true; expires=${expires}; Path=/`;
    msalInstance.loginRedirect();
  },
  Adb2cObjectHandle: (storeObj, { token }) => {
    storeObj.commit("setToken", token);
  },
};
const mutations = {
  setToken: (state, token) => {
    localStorage.setItem("tokenAdb2c", token);
  },
  resetAuthModule: (state) => {
    localStorage.clear();
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
