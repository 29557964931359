import {
  LogLevel,
  PublicClientApplication,
  Configuration,
} from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId:
      process.env.VUE_APP_IS_BRANCH_PROD == "1"
        ? (process.env.VUE_APP_MSAL_CLIENT_ID_PROD as string)
        : (process.env.VUE_APP_MSAL_CLIENT_ID_TEST as string),
    authority:
      process.env.VUE_APP_IS_BRANCH_PROD == "1"
        ? (process.env.VUE_APP_MSAL_LOGIN_AUTHORITY_PROD as string)
        : process.env.VUE_APP_MSAL_LOGIN_AUTHORITY_TEST,
    knownAuthorities:
      process.env.VUE_APP_IS_BRANCH_PROD == "1"
        ? [process.env.VUE_APP_MSAL_KNOWN_AUTHORITY_PROD as string]
        : [process.env.VUE_APP_MSAL_KNOWN_AUTHORITY_TEST as string],
    // clientSecret: process.env.VUE_APP_MSAL_CLIENT_SECRET_TEST,
    postLogoutRedirectUri: "/", // Must be registered as a SPA redirectURI on your app registration
    redirectUri:
      process.env.NODE_ENV === "development"
        ? process.env.VUE_APP_MSAL_REDIRECT_URI_DEV
        : process.env.VUE_APP_IS_BRANCH_PROD == "1"
        ? process.env.VUE_APP_MSAL_REDIRECT_URI_PROD
        : process.env.VUE_APP_MSAL_REDIRECT_URI_MAIN,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            //console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            return;
          case LogLevel.Warning:
            //console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

export const msalConfigReset: Configuration = {
  auth: {
    clientId:
      process.env.VUE_APP_IS_BRANCH_PROD == "1"
        ? (process.env.VUE_APP_MSAL_CLIENT_ID_PROD as string)
        : (process.env.VUE_APP_MSAL_CLIENT_ID_TEST as string),
    authority:
      process.env.VUE_APP_IS_BRANCH_PROD == "1"
        ? (process.env.VUE_APP_MSAL_RESET_AUTHORITY_PROD as string)
        : (process.env.VUE_APP_MSAL_RESET_AUTHORITY_TEST as string),
    knownAuthorities:
      process.env.VUE_APP_IS_BRANCH_PROD == "1"
        ? [process.env.VUE_APP_MSAL_KNOWN_AUTHORITY_PROD as string]
        : [process.env.VUE_APP_MSAL_KNOWN_AUTHORITY_TEST as string],
    // clientSecret: process.env.VUE_APP_MSAL_CLIENT_SECRET_TEST,
    postLogoutRedirectUri: "/", // Must be registered as a SPA redirectURI on your app registration
    redirectUri:
      process.env.NODE_ENV === "development"
        ? process.env.VUE_APP_MSAL_REDIRECT_URI_DEV
        : process.env.VUE_APP_IS_BRANCH_PROD == "1"
        ? process.env.VUE_APP_MSAL_REDIRECT_URI_PROD
        : process.env.VUE_APP_MSAL_REDIRECT_URI_MAIN,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
export const msalInstanceReset = new PublicClientApplication(msalConfigReset);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  //   scopes: ["User.Read"],
  scopes: [
    "openid",
    "profile",
    process.env.VUE_APP_IS_BRANCH_PROD == "1"
      ? (process.env.VUE_APP_MSAL_CLIENT_ID_PROD as string)
      : (process.env.VUE_APP_MSAL_CLIENT_ID_TEST as string),
  ],
  // scopes: ["write", "read"],
};

export const silentTokenRequest = {
  //   scopes: ["User.Read"],
  scopes: [
    process.env.VUE_APP_IS_BRANCH_PROD == "1"
      ? (process.env.VUE_APP_MSAL_CLIENT_ID_PROD as string)
      : (process.env.VUE_APP_MSAL_CLIENT_ID_TEST as string),
  ],
  // scopes: ["write", "read"],
};

export const resetPasswordRequest = {
  //   scopes: ["User.Read"],
  scopes: [
    process.env.VUE_APP_IS_BRANCH_PROD == "1"
      ? (process.env.VUE_APP_MSAL_CLIENT_ID_PROD as string)
      : (process.env.VUE_APP_MSAL_CLIENT_ID_TEST as string),
  ],
  // scopes: ["write", "read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
