import { requests } from "../db/dbManag";
export const checkTokenFromFCH = (token) => {
  requests.dbManagControlloTokenFCH.interceptors.request.use((config) => {
    config.headers["Authorization"] = "bearer " + token;
    return config;
  });
  return requests.dbManagControlloTokenFCH
    .post("v1/connecthub/login")
    .then((res) => {
      return res.status;
    })
    .catch((e) => {
      return e.response.status;
    });
};
